// Color Variable
$theme-color: #2B96CC;
$white: #ffffff;
$black: #000000;

// Gray
$color1:#444A4D;
$color2:#778288;
$color3:#A4B4BD;
$color4:#D5E2EA;
$color5:#E2F1F9;

$success: #089B55;
$danger: orange;
$warning: #E5B64B;
$info: #448FB8;

// media quary
$xl: 1199px;
$lg: 991px;
$md: 767px;
$sm: 575px;