// Fonts Size
// h1 {
//     font-size: 45px;
// }
h2 {
    font-size: 48px;
}

h3 {
    font-size: 32px;
}

// h4 {
//     font-size: 18px;
// }
// h5 {
//     font-size: 16px;
// }
p {
    font-size: 16px;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-success {
    color: $success;
}

.text-danger {
    color: $danger;
    margin-top: 5px;
}

.text-warning {
    color: $warning;
}

.text-info {
    color: $info;
}

.text-minute {
    color: $color2;
}

.position-relative {
    position: relative;
}

// Font Family


// Width css
.w-100 {
    width: 100%;
}

// Height SCSS
.min-h100 {
    min-height: 100vh;
}

.h-100 {
    height: 100%;
}
.p-0{
    padding: 0 !important;
}
.pr-0{
    padding-right: 0 !important;
}
.pl-0{
    padding-left: 0 !important;
}

.container {
    max-width: 1140px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}

.modal-content{
    .modal-header{
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
        h4{
            line-height: 1;
        }
    }
    .modal-body {
        position: relative;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1rem;
    }
    .modal-header .close {
      margin-right: 0;
    }
}

@include responsive(lg) {
    .container {
        max-width: 960px;
    }
}

@include responsive(md) {
    .container {
        max-width: 720px;
    }
}

@include responsive(sm) {
    .container {
        max-width: 540px;
    }
}