* {
    margin:0px;
    padding:0px;
    border:none;
    outline:none;
    @include prefix (box-sizing, border-box);
    -webkit-tap-highlight-color: transparent;
    font-family: 'Poppins';
}

html {
    @include prefix (text-size-adjust, none);
}

body {
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Poppins';
    //background-image: url("../images/indian_flag.jpg");
    //background-image: url("../images/indian-flag-ribbon.jpg");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    // position: relative;
    // &:before{
    //     content: '';
    //     background-color: rgba(0,0,0,0.2);
    //     position: absolute;
    //     top:0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     margin: auto;
    // }
}

input,textarea,select{
    border: 1px solid rgba(43,105,204,0.2);
    font-weight: 400;
    height: 45px;
    display: inline-block;
    padding: 5px 15px;
    width: 100%;
    border-radius: 5px;
    font-family: 'Poppins';
    font-size: 14px;
    color: #030303;
    &.text-area{
        resize: none;
        height: 200px;
        padding: 10px 15px;
    }
}

a {
    text-decoration: none;
    &:hover,&:focus {
        @extend a;
    }
}
button {
    outline: 0;

    &:hover, &:focus {
        @extend button;
    }
}

h1, h2, h3, h4, h5, h6, strong, b {
    margin: 0;

}
.text-white {
    h1, h2, h3, h4, h5, h6, strong, b, p, ul, ol, li {
        color: $white;
    }
}
.text-gray {
    h1, h2, h3, h4, h5, h6, strong, b, p, ul, ol, li, .timer {
        color: $color1;
    }
}
p {
    margin: 0;
    line-height: normal;

}

ul,ol {
    list-style: none;
    margin: 0;
    padding: 0;
}
div,aside,figure,figcaption, label {
    margin: 0;
}
section,header,footer{display: inline-block;width: 100%;}
%transition {
    @include prefix( transition, all 0.3s ease-in-out);
}

input,textarea,select,header{
    @include prefix (appearance, none);
}
::-moz-selection {
    background: #389dab;
    color: $white;
}
::selection {
    background: #389dab;
    color: $white;
}
::-webkit-input-placeholder {color: $color2;opacity: 1;}
:-moz-placeholder { color: $color2;opacity: 1;}
::-moz-placeholder { color: $color2;opacity: 1;}
:-ms-input-placeholder {color: $color2;opacity: 1;}